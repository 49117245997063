
.start-actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn-primary {
    padding: 1em;
    font-size: 24pt;
  }

  .btn-secondary {
    margin-top: 0.5em;

    padding: 0.25em 1em;
  }
}
