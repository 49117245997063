.layout-simple {
  display: flex;
  flex-direction: column;
  align-content: center;

  #navbar {
    $activeLinkColor: mix(#AED581, black, 75%);

    .navbar-brand {
      font-size: 20pt;
      color: $activeLinkColor;


    }

    .navbar-nav {
      align-items: center;
    }
  }

  > main {
    margin-top: 2em;
    margin-bottom: 15vh;
  }

  .app-footer {
    $width: 66.6vw;

    position: fixed;
    margin-left: (100vw - $width) / 2;
    bottom: 2em;

    min-height: 2em;
    width: $width;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-shadow: rgba(gray, 0.5) 0 0 6px 1px;
  }

  #navbar, .app-footer {
    nav {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        padding: 0.25em;
      }
    }

    .spacer {
      display: inline-block;
      width: 0.25em;
      height: 0.25em;
      border-radius: 50%;
      background: black;

      margin: 0 0.5em;
    }
  }

}
